import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import QuickSightFrame from '../../../shared-components/QuickSightFrame';
import PageHeader from '../../../shared-components/PageHeader';
import {
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_CP,
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_MNO,
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_RESELLER,
} from '../../../services/constants';
import {
  convertMnoNamesToIDs,
  convertProductFamilyNamesToIDs,
  getMergedPermissionAttribute,
} from '../../../services/reportsUtil';
import { getMnos, getProductFamilies } from '../store/reportsSlice';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import { SELECT_ALL_ITEM_VALUE } from '../../admin/permission/constants';
import { useGetMnosQuery } from '../../support/store/mnosApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const ACCOUNT_TAG_CP = 'cpBillAccId';
const ACCOUNT_TAG_MNO = 'mnoBillAccId';
const ACCOUNT_TAG_RESELLER = 'resBillAccId';
const ATTRIBUTE_NAMES_BY_ACCOUNT_TAG = {
  [ACCOUNT_TAG_CP]: ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_CP,
  [ACCOUNT_TAG_MNO]: ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_MNO,
  [ACCOUNT_TAG_RESELLER]: ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_RESELLER,
};
const DEFAULT_MNO_SELECTED = 'mtn_sa';

function SummaryReport() {
  const dispatch = useDispatch();
  const { permissions, userSettings } = useSelector(({ auth }) => auth.user);
  const { data: mnos, isLoading: loadingMnos } = useGetMnosQuery();
  const { loadingProductFamilies, productFamilies } = useSelector(
    ({ journey }) => journey.reports
  );
  const billingAccountTagName = getBillingAccountTagName();

  function getTags() {
    const mno = getMergedPermissionAttribute(permissions, 'mno');
    const billingAccountId = getMergedPermissionAttribute(permissions, 'billingAccount');
    const productFamily = getMergedPermissionAttribute(permissions, 'productFamily');

    return {
      [billingAccountTagName]: billingAccountId.toString(),
      mno_ids: convertMnoNamesToIDs(mnos, mno).toString(),
      mno_product_family_ids: convertProductFamilyNamesToIDs(
        mnos,
        productFamilies,
        productFamily
      ).toString(),
    };
  }

  function getUrlParams() {
    if (!permissions.length || billingAccountTagName !== ACCOUNT_TAG_MNO) return null;
    const firstMno = permissions[0].mno[0];
    return { mno: firstMno === SELECT_ALL_ITEM_VALUE ? DEFAULT_MNO_SELECTED : firstMno };
  }

  useEffect(() => {
    if (!productFamilies.length) dispatch(getProductFamilies());
  }, []);

  function getBillingAccountTagName() {
    if (userSettings) {
      if (userSettings.viewMNOBillingReport) return ACCOUNT_TAG_MNO;
      if (userSettings.viewResellerBillingReport) return ACCOUNT_TAG_RESELLER;
    }
    return ACCOUNT_TAG_CP;
  }

  return (
    <>
      <Root
        header={<PageHeader iconName="layers" title="Summary" noHeaderButton />}
        content={
          loadingMnos || loadingProductFamilies ? (
            <FuseLoading />
          ) : (
            <QuickSightFrame
              dashboardIdAttributeName={ATTRIBUTE_NAMES_BY_ACCOUNT_TAG[billingAccountTagName]}
              tags={getTags()}
              urlParams={getUrlParams()}
            />
          )
        }
        innerScroll
      />
    </>
  );
}

export default SummaryReport;
