import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import { getCodes, getProductFamilies, selectCodes } from '../store/codesSlice';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import { getContracts } from '../store/contractsSlice';
import { isUserBillingAdmin } from '../../../services/permissionUtil';
import { filterByMnoStatus } from '../utils';
import { useGetMnosQuery } from '../../support/store/mnosApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = (codes, productFamilyMap) => [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'mno', headerName: 'MNO', flex: 90 },
  { field: 'name', headerName: 'Name', flex: 100 },
  {
    field: 'method',
    headerName: 'Method',
    flex: 200,
    type: 'singleSelect',
    valueOptions: [...new Set(codes.map((code) => code.method))],
  },
  {
    field: 'productFamilyId',
    headerName: 'Product Family',
    flex: 150,
    valueGetter: (params) => {
      return params.row.productFamilyId ? productFamilyMap.get(params.row.productFamilyId) : '';
    },
  },
];

function Codes(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, productFamilies } = useSelector(({ billing }) => billing.codes);
  const { data: mnos = [] } = useGetMnosQuery();
  const codes = filterByMnoStatus(mnos, useSelector(selectCodes));
  const user = useSelector(({ auth }) => auth.user);
  const isBillingAdmin = isUserBillingAdmin(user);
  const productFamilyMap = getProductFamilyMap();

  function getProductFamilyMap() {
    const map = new Map();
    productFamilies?.forEach(({ id, productFamilyName }) => map.set(id, productFamilyName));
    return map;
  }

  useEffect(() => {
    dispatch(getCodes());
    dispatch(getProductFamilies());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getContracts());
  }, [dispatch]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <Root
      header={
        <PageHeader
          iconName="code"
          title="Billing Codes"
          buttonName="New Code"
          noHeaderButton={!isBillingAdmin}
          buttonLink="/billing-admin/codes/new"
        />
      }
      content={
        <CustomDataGrid
          name="codes"
          rows={codes}
          columns={columns(codes, productFamilyMap)}
          density="compact"
          onRefresh={() => dispatch(getCodes())}
          onRowClick={(row) => navigate(`/billing-admin/codes/${row.id}`)}
        />
      }
      innerScroll
    />
  );
}

export default withReducer('billing', reducer)(Codes);
