import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import _ from '@lodash';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import { getOrderStatusByName, MNO_MAP, OrderStatus } from '../../../services/constants';
import filterUtil from '../../../services/filterUtil';

const logger = new ComponentLogger('OrdersSlice');

export const selectEntitiesArray = (state) => {
  const { ids, entities } = state;
  return ids.map((id) => entities[id]);
};

export const getOrders = createAsyncThunk(
  'journey/orders/getOrders',
  async (parameters, { getState }) => {
    const state = getState(); // <-- invoke
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken().toString();
    const response = await API.graphql({
      query: queries.searchJourneyOrders,
      variables: {
        limit: 50,
        filter: filterUtil.evaluateFilter(
          parameters.filter,
          state.auth.user.permissions,
          selectEntitiesArray(state.billing.accounts),
          selectEntitiesArray(state.admin.templates)
        ),
        sort: [{ field: 'validFrom', direction: 'desc' }],
        ...(parameters &&
          (parameters.nextToken || state.journey.orders.tokenMap[parameters.pageNumber]) && {
            nextToken: parameters.nextToken || state.journey.orders.tokenMap[parameters.pageNumber],
          }),
      },
      authMode: 'AWS_LAMBDA',
      authToken: `custom-${jwtToken}`,
    }).catch((error) => {
      logger.error('getOrders', { error });
    });

    const responseBilling = await API.get('billing-api', 'accounts');
    return {
      data: response.data.searchJourneyOrders.items.map((order) => {
        return {
          ...order,
          definition: {
            ...order.definition,
            billingAccountName: responseBilling.find(
              (account) => account.id === order.billingAccountId
            ).name,
          },
        };
      }),
      nextToken: response.data.searchJourneyOrders.nextToken,
      pageNumber: (parameters && parameters.pageNumber) || 0,
      total: response.data.searchJourneyOrders.total,
      parameters,
    };
  }
);

export const updateOrderStatus = createAsyncThunk(
  'journey/order/updateOrderStatus',
  async (order, { dispatch }) => {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken().toString();
    let data;
    const orderResponseById = await API.graphql({
      query: queries.getJourneyOrder,
      variables: { id: order.id },
      authMode: 'AWS_LAMBDA',
      authToken: `custom-${jwtToken}`,
    }).catch((error) => logger.error('updateOrderStatus', { error }));
    data = orderResponseById.data.getJourneyOrder;
    if (isOrderStateValid(order.status, data.status, dispatch)) {
      const newOrder = await API.graphql({
        query: mutations.updateJourneyOrder,
        variables: { input: order },
        authMode: 'AWS_LAMBDA',
        authToken: `custom-${jwtToken}`,
      }).catch((error) => {});
      data = newOrder.data.updateJourneyOrder;
      dispatch(
        showMessage({
          message: `Order has been ${_.lowerCase(order.status)} successfully!`,
          variant: 'success',
        })
      );
    }
    const responseBilling = await API.get('billing-api', 'accounts');
    return {
      ...data,
      definition: {
        ...data.definition,
        billingAccountName: responseBilling.find((account) => account.id === data.billingAccountId)
          .name,
      },
    };
  }
);

function isOrderStateValid(modifiedOrderState, existingOrderState, dispatch) {
  if (getOrderStatusByName(existingOrderState).allowedStates().length === 0) {
    if (
      getOrderStatusByName(existingOrderState).name === OrderStatus.TERMINATED.name &&
      modifiedOrderState === OrderStatus.TERMINATED.name
    ) {
      dispatch(
        showMessage({
          message: `The Order has already been terminated. Please “Refresh” the Orders List.`,
          variant: 'error',
        })
      );
    } else {
      dispatch(
        showMessage({
          message: `Order with ${_.lowerCase(existingOrderState)} status cannot be updated!`,
          variant: 'error',
        })
      );
    }
    return false;
  }
  if (
    !getOrderStatusByName(existingOrderState)
      .allowedStates()
      .includes(getOrderStatusByName(modifiedOrderState))
  ) {
    if (
      getOrderStatusByName(existingOrderState).name === OrderStatus.ACTIVE.name &&
      modifiedOrderState === OrderStatus.SCHEDULED.name
    ) {
      dispatch(
        showMessage({
          message: `The Order has already been activated. Please “Refresh” the Orders List.`,
          variant: 'error',
        })
      );
    } else if (
      getOrderStatusByName(existingOrderState).name === OrderStatus.ACTIVE.name &&
      modifiedOrderState === OrderStatus.CANCELED.name
    ) {
      dispatch(
        showMessage({
          message: `The Order has already been activated. Please “Refresh” the Orders List and try to terminate it.`,
          variant: 'error',
        })
      );
    } else {
      dispatch(
        showMessage({
          message: `Order with ${_.lowerCase(existingOrderState)} status cannot be ${_.lowerCase(
            modifiedOrderState
          )}!`,
          variant: 'error',
        })
      );
    }
    return false;
  }
  return true;
}

function setAuthorizedAmountIfNeeded(order, state) {
  if ([OrderStatus.SCHEDULED.name, OrderStatus.WAITING_FOR_APPROVAL.name].includes(order.status)) {
    const billingPrice = order.bidPrice ? null : getBillingPrice(order, state);
    order.authorizedAmount = getOrderCost(
      order.mno,
      order.bidPrice,
      billingPrice,
      order.targetRequested
    );
  } else if ([OrderStatus.REJECTED.name, OrderStatus.CANCELED.name].includes(order.status)) {
    order.authorizedAmount = 0;
  }
}

function getBillingPrice(order, state) {
  const { entities: prices } = state.billing.prices;
  return Object.values(prices).find((p) => p.codeId === order.billingCodeId);
}

export function getOrderCost(mno, bidPrice, billingPrice, targetRequested) {
  if (bidPrice) return (bidPrice * targetRequested * MNO_MAP[mno].priceConstant) / 1000;

  return billingPrice.subscriberPrice * targetRequested * MNO_MAP[mno].priceConstant;
}

export const saveOrder = createAsyncThunk(
  'journey/order/saveOrder',
  async (payload, { dispatch, getState }) => {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken().toString();
    const isDraft = payload.order.status === OrderStatus.DRAFT.name;
    let data;
    const state = getState();
    const { entities: accounts } = state.billing.accounts;
    setAuthorizedAmountIfNeeded(payload.order, state);

    if (!payload.order.id) {
      const { billingType, ...rest } = payload.order;
      const response = await API.graphql({
        query: mutations.createJourneyOrder,
        variables: { input: rest },
        authMode: 'AWS_LAMBDA',
        authToken: `custom-${jwtToken}`,
      }).catch((error) => {
        logger.error('saveOrderCreateOrder', { data: payload.order, error });
      });

      data = response.data.createJourneyOrder;
      dispatch(
        showMessage({
          message: isDraft
            ? 'Draft has been saved successfully!'
            : 'Order has been placed successfully!',
          variant: 'success',
        })
      );
    } else {
      const {
        createdAt,
        updatedAt,
        version,
        _version,
        _deleted,
        _lastChangedAt,
        executionStatuses,
        orderEvents,
        billingType,
        definition,
        ...rest
      } = payload.order;
      if (definition?.billingAccountName) {
        const { billingAccountName, ...restDefinition } = definition;
        rest.definition = restDefinition;
      } else {
        rest.definition = definition;
      }

      const orderResponseById = await API.graphql({
        query: queries.getJourneyOrder,
        variables: { id: payload.order.id },
        authMode: 'AWS_LAMBDA',
        authToken: `custom-${jwtToken}`,
      }).catch((error) => logger.error('saveOrderGetOrder', { error }));
      data = orderResponseById.data.getJourneyOrder;
      if (payload.isApprove && data.status === OrderStatus.SCHEDULED.name) {
        dispatch(
          showMessage({
            variant: 'error',
            message: 'The Order has already been approved. Please “Refresh” the Orders List.',
          })
        );
        return null;
      }
      if (isOrderStateValid(payload.order.status, data.status, dispatch)) {
        const response = await API.graphql({
          query: mutations.updateJourneyOrder,
          variables: { input: rest },
          authMode: 'AWS_LAMBDA',
          authToken: `custom-${jwtToken}`,
        }).catch((error) => {
          logger.error('saveOrderUpdateOrder', { data: rest, error });
        });

        data = response.data.updateJourneyOrder;
        dispatch(
          showMessage({
            message: `${isDraft ? 'Draft' : 'Order'} has been updated successfully!`,
            variant: 'success',
          })
        );
      }
    }
    return {
      ...data,
      definition: {
        ...data.definition,
        billingAccountName: Object.values(accounts).find(
          (account) => account.id === payload.order.billingAccountId
        )?.name,
      },
    };
  }
);

const ordersAdapter = createEntityAdapter({});

export const { selectAll: selectOrders, selectById: selectOrderById } = ordersAdapter.getSelectors(
  (state) => state.journey.orders
);

const ordersSlice = createSlice({
  name: 'journey/orders',
  initialState: ordersAdapter.getInitialState({
    nextToken: null,
    loading: true,
    total: 0,
    tokenMap: { 0: null },
    accountDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    refreshOrder: (state, action) => {
      ordersAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.loading = true;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.parameters && action.payload.parameters.keepNewOrder) {
        ordersAdapter.upsertMany(state, action.payload.data);
      } else {
        ordersAdapter.setAll(state, action.payload.data);
      }
      state.tokenMap[action.payload.pageNumber + 1] = action.payload.nextToken;
      state.nextToken = action.payload.nextToken;
      state.total = action.payload.total || 0;
      state.rowCount = action.payload.data.length || 0;
    },
    [updateOrderStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      state.loading = false;
      ordersAdapter.upsertOne(state, action.payload);
    },
    [saveOrder.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) ordersAdapter.upsertOne(state, action.payload);
    },
  },
});

export const { refreshOrder } = ordersSlice.actions;

export default ordersSlice.reducer;
