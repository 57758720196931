import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import fuse from './fuse';
import i18n from './i18nSlice';
import { accountsApi } from '../main/billing-admin/store/accountsApi';
import { mnosApi } from '../main/support/store/mnosApi';
import { paymentEntryApi } from '../main/billing-admin/store/paymentEntryApi';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    fuse,
    i18n,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [mnosApi.reducerPath]: mnosApi.reducer,
    [paymentEntryApi.reducerPath]: paymentEntryApi.reducer,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'auth/user/userLoggedOut') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
