/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API, Storage } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { generateGraphQLFilter } from 'app/services/filterUtil';
import * as queries from '../../../../graphql/queries';

const logger = new ComponentLogger('OrderSlice');

const orderAdapter = createEntityAdapter({});
const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };
export const getOrder = createAsyncThunk('journey/order/getOrder', async (params) => {
  const response = await API.graphql({
    query: queries.getJourneyOrder,
    variables: { input: params },
  }).catch((error) => {
    logger.error('getOrder', { data: params, error });
  });

  const data = await response.data.getJourneyOrder;

  return data === undefined ? null : data;
});

const collectCampaignTemplateIds = (dataList) => {
  const campaignTemplates = dataList.flatMap((item) => item.campaignTemplate || []);
  return campaignTemplates.includes('*') ? ['*'] : campaignTemplates;
};

export const getPaymentInformation = createAsyncThunk(
  'journey/order/accountInformation',
  async (params, { getState }) => {
    const { mno, accountId, currency, invalidate = true } = params;
    const state = getState();
    if (!currency) return null;
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken().toString();
    const response = await API.graphql({
      query: queries.searchJourneyOrders,
      variables: {
        filter: {
          and: [
            {
              mno: {
                eq: mno,
              },
            },
            {
              billingAccountId: {
                eq: accountId,
              },
            },
            {
              authorizedAmount: {
                gt: 0,
              },
            },
            {
              ...generateGraphQLFilter(
                // set templateIds from permissions
                'templateId',
                collectCampaignTemplateIds(state.auth.user.permissions)
              ),
            },
          ],
        },
      },

      authMode: 'AWS_LAMBDA',
      authToken: `custom-${jwtToken}`,
    }).catch((error) => {
      logger.error('getPaymentInformationListJourneyOrders', { accountId, error });
    });
    const orders = response.data.searchJourneyOrders.items;
    const authorizedAmount =
      orders && orders.length
        ? orders.reduce((a, b) => {
            return a + b.authorizedAmount;
          }, 0)
        : 0;

    const paymentInformation = await API.get(
      'billing-api',
      `accounts/${accountId}/balance?currency=${currency}`,
      invalidate ? invalidateCacheHeader : {}
    ).catch((err) => {
      logger.error('getPaymentInformationBalance', { accountId, error });
    });

    return {
      authorizedAmount,
      amountAvailable:
        paymentInformation.balance - authorizedAmount + paymentInformation.accountLimit,
      ...paymentInformation,
    };
  }
);

export const uploadFilesToS3 = createAsyncThunk(
  'journey/order/saveOrderFile',
  async (fileList, { dispatch }) => {
    const totalSize = fileList.reduce((accumulator, file) => {
      return accumulator + file.size;
    }, 0);
    const promises = [];
    fileList.map((file) => promises.push(doUpload(file, totalSize, dispatch)));
    try {
      return await Promise.all(promises);
    } catch (error) {
      logger.error('uploadFilesToS3', { error });
      return [];
    }
  }
);

const doUpload = (file, totalSize, dispatch) =>
  new Promise((resolve, reject) => {
    Storage.put(file.name, file, {
      level: 'protected',
      contentType: 'text/plain',
      useAccelerateEndpoint: true,
      resumable: true,
      completeCallback: (event) => {
        resolve(event);
      },
      progressCallback: (progress) => {
        dispatch(
          updateFileUploadPercentage({
            loaded: progress.loaded,
            fileName: file.name,
            totalSize,
          })
        );
      },
      errorCallback: (error) => {
        logger.error('doUpload', { error });
      },
    });
  });

export async function getS3FileSize(key) {
  try {
    const result = await Storage.list(key, {
      level: 'protected',
      useAccelerateEndpoint: true,
      pageSize: 1,
    });
    if (result?.results?.length) return result.results[0].size;
  } catch (error) {
    logger.error('getS3FileSize', { error });
  }
  return 0;
}

export const deleteExcludedMsisdnFile = createAsyncThunk(
  'journey/order/deleteOrderExclusionFile',
  async (fileName) => {
    const data = await Storage.remove(fileName, {
      level: 'protected',
      useAccelerateEndpoint: true,
    }).catch((error) => {
      logger.error('deleteExcludedMsisdnFile', { data: fileName, error });
    });
    return data;
  }
);

const orderSlice = createSlice({
  name: 'journey/order',
  initialState: orderAdapter.getInitialState({
    loading: false,
    rejectOrderDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
    accountPaymentInformation: null,
    accountPaymentInformationLoading: false,
    percentage: 0,
    loaded: {},
    isPlaceOrderDisabled: null,
  }),
  reducers: {
    openRejectOrderDialog: (state, action) => {
      state.rejectOrderDialog = {
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeRejectOrderDialog: (state, action) => {
      state.rejectOrderDialog = {
        props: {
          open: false,
        },
        data: null,
      };
    },
    resetOrder: () => null,
    updateFileUploadPercentage: (state, action) => {
      state.loaded[action.payload.fileName] = action.payload.loaded;
      const totalLoaded = Object.values(state.loaded).reduce((a, b) => a + b, 0);
      state.percentage =
        totalLoaded >= action.payload.totalSize
          ? 100
          : Math.round((100 * totalLoaded) / action.payload.totalSize);
    },
    setPlaceOrderDisabled: (state, action) => {
      state.isPlaceOrderDisabled = action.payload;
    },
  },
  extraReducers: {
    [getOrder.fulfilled]: (state, action) => action.payload,
    [getPaymentInformation.pending]: (state, action) => {
      state.accountPaymentInformationLoading = true;
    },
    [getPaymentInformation.fulfilled]: (state, action) => {
      state.accountPaymentInformation = action.payload;
      state.accountPaymentInformationLoading = false;
    },
    [uploadFilesToS3.pending]: (state) => {
      state.loading = true;
      state.loaded = {};
      state.percentage = 0;
    },
    [uploadFilesToS3.fulfilled]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  resetOrder,
  openRejectOrderDialog,
  closeRejectOrderDialog,
  updateFileUploadPercentage,
  setPlaceOrderDisabled,
} = orderSlice.actions;

export default orderSlice.reducer;
