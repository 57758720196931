import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import Root from 'app/shared-components/Root';
import FormInputText from 'app/shared-components/FormInputText';
import FormMnoDropdown from 'app/shared-components/FormMnoDropdown';
import FormAccountDropdown from 'app/shared-components/FormAccountDropdown';
import { v4 as uuidv4 } from 'uuid';
import { showMessage } from 'app/store/fuse/messageSlice';
import FormInputDropdown from 'app/shared-components/FormInputDropdown';
import { LoadingButton } from '@mui/lab';
import _ from '@lodash';
import PageHeader from '../../../shared-components/PageHeader';
import reducer from '../store';
import { useSavePaymentEntryMutation } from '../store/paymentEntryApi';
import { useGetMnosQuery } from '../../support/store/mnosApi';
import { useGetAccountsQuery } from '../store/accountsApi';

const schema = yup.object().shape({
  mnoId: yup.string().required('MNO ID is required'),
  dateTime: yup.date().required('Currency is required'),
  billingAccountId: yup.string().required('Billing Account is required'),
  amount: yup.number().required('Amount is required'),
  currency: yup.string().required('Currency is required'),
  description: yup.string().max(1000, 'Description is too long'),
  paymentSystemData: yup.number().required('Fee information is required'),
  paymentSystemName: yup.string().required('Payment System Name is required'),
});

const defaultValues = {
  dateTime: new Date().toISOString(),
  mnoId: '',
  billingAccountId: '',
  amount: '',
  currency: '',
  description: '',
  paymentSystemName: 'Manual Entry',
  paymentSystemData: '',
};

function PaymentEntry() {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [save, result] = useSavePaymentEntryMutation();
  const { data: mnoRecords } = useGetMnosQuery();
  const accounts = useGetAccountsQuery();
  const currencyList = process.env.REACT_APP_CURRENCY_LIST.split(',');

  const {
    control,
    formState: { errors, dirtyFields, isValid },
    getValues,
    setValue,
    reset,
    handleSubmit,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange',
  });

  const mno = watch('mnoId');

  const onSubmit = async (event) => {
    event.preventDefault();
    let data = structuredClone(getValues());
    const id = uuidv4().replace(/-/g, '');
    data = {
      ...data,
      id,
      username: user.data.email,
      paymentSystemData: JSON.stringify({ fee: data.paymentSystemData }),
      paymentSystemTransactionId: id,
      mnoId: mnoRecords.find((r) => r.mno === mno)?.id,
    };
    try {
      await save(data).unwrap();
      reset(defaultValues);
      dispatch(showMessage({ message: 'Payment Entry saved successfully!', variant: 'success' }));
    } catch (error) {
      dispatch(showMessage({ message: "Payment Entry couldn't be saved", variant: 'error' }));
    }
  };

  return (
    <>
      <Root
        header={<PageHeader iconName="money" title="Payment Entry" noHeaderButton />}
        content={
          <form className="my-32 mx-auto flex flex-col gap-16 w-full max-w-[800px]">
            <FormInputText
              name="dateTime"
              control={control}
              label="Payment Date"
              type="date"
              placeholder="Payment Date"
              fullWidth
            />
            <FormMnoDropdown
              name="mnoId"
              control={control}
              label="MNO"
              type="text"
              fullWidth
              required
              customHandler={() => {
                setValue('billingAccountId', '')
                trigger(['billingAccountId', 'mnoId'])
              }}
            />
            {mno && (
              <FormAccountDropdown
                mno={mno}
                name="billingAccountId"
                control={control}
                label="Billing Account ID"
                fullWidth
                required
              />
            )}
            <FormInputText
              name="amount"
              control={control}
              label="Amount Paid"
              type="number"
              placeholder="Amount Paid"
              fullWidth
            />
            <FormInputText
              control={control}
              name="paymentSystemData"
              label="Payment Fee"
              placeholder="Payment Fee"
              fullWidth
            />
            <FormInputDropdown
              label="Currency"
              name="currency"
              required
              control={control}
              options={currencyList.map((val) => ({ value: val }))}
            />
            <FormInputText
              name="paymentSystemName"
              control={control}
              label="Payment System Name"
              placeholder="Payment System Name"
              disabled
              fullWidth
            />
            <FormInputText
              name="description"
              control={control}
              label="Description"
              placeholder="Description"
              multiline
              rows={4}
              fullWidth
            />
            <LoadingButton
              disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={onSubmit}
              loading={result.isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Add Payment
            </LoadingButton>
          </form>
        }
      />
    </>
  );
}

export default withReducer('billing', reducer)(PaymentEntry);
